exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-gdpr-tsx": () => import("./../../../src/pages/legal/gdpr.tsx" /* webpackChunkName: "component---src-pages-legal-gdpr-tsx" */),
  "component---src-pages-legal-privacy-tsx": () => import("./../../../src/pages/legal/privacy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-tsx" */),
  "component---src-pages-legal-sla-tsx": () => import("./../../../src/pages/legal/sla.tsx" /* webpackChunkName: "component---src-pages-legal-sla-tsx" */),
  "component---src-pages-legal-terms-tsx": () => import("./../../../src/pages/legal/terms.tsx" /* webpackChunkName: "component---src-pages-legal-terms-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-wave-index-tsx": () => import("./../../../src/pages/wave/index.tsx" /* webpackChunkName: "component---src-pages-wave-index-tsx" */),
  "component---src-templates-feature-tsx": () => import("./../../../src/templates/feature.tsx" /* webpackChunkName: "component---src-templates-feature-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-posts-tsx": () => import("./../../../src/templates/posts.tsx" /* webpackChunkName: "component---src-templates-posts-tsx" */)
}

