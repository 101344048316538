module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"UCare","short_name":"UCare","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"content/assets/favicon/icon.png","icons":[{"src":"/content/assets/favicon/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"/content/assets/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/content/assets/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a23448a7cad6b5a9c448957c3712d13b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
